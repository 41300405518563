.Button {
    font-family: $font-oswald;
    font-weight: 700;
    text-transform: uppercase;

    // Primary
    &--primary {
        background-color: $cc-purple;
        box-shadow: 0 22px 44px 0 rgba($cc-purple, 0.3);
        color: $white;

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            color: $white;
            background-color: $cc-purple--hover;
        }
    }

    // Secondary
    &--secondary {
        background-color: $white;
        color: $cc-black;

        .Icon {
            height: auto;
            width: auto;
        }

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            background-color: $cc-aqua;
            color: $white;
        }
    }

    // Success
    &--success {
        background-color: $green;
        border-color: $green;
        color: $white;

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            background-color: $green--700;
            border-color: $green--700;
            color: $white;
        }
    }

    // Info
    &--info {
        background-color: $blue;
        border-color: $blue;
        color: $white;

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            background-color: $blue--700;
            border-color: $blue--700;
            color: $white;
        }
    }

    // Warning
    &--warning {
        background-color: $orange;
        border-color: $orange;
        color: $white;

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            background-color: $orange--700;
            border-color: $orange--700;
            color: $white;
        }
    }

    // Danger
    &--danger {
        background-color: $red;
        border-color: $red;
        color: $white;

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            background-color: $red--700;
            border-color: $red--700;
            color: $white;
        }
    }

    // Large
    &--large {
        @include font-size($button-font-size * 1.25, false);
        padding-right: u($spacing-unit--lg);
        padding-left: u($spacing-unit--lg);
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($spacing-unit--sm);
        padding-left: u($spacing-unit--sm);
    }

    // Full
    &--full {
        width: 100%;
    }

    // Icon
    &--icon {
        display: inline-flex;
        align-items: center;

        .Icon {
            margin-left: u($spacing-unit);
        }
    }

    &-icon {
        fill: currentColor;
        margin-right: u(16px);

        &--mail {
            height: u(16px);
            width: u(19.6px);
        }

        &--phone {
            height: u(24px);
            width: u(16px);
        }
    }
}