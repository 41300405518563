/**
 * Heading
 */

a {
    color: $link-color;
    text-decoration: $link-text-decoration;

    &:hover,
    &:focus,
    &:active {
        color: $link-color--hover;
        text-decoration: $link-text-decoration--hover;
    }
}

a, button, input[type="button"] {
    transition: background-color .2s, color .2s;
}
