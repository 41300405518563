/**
 * Colors: Custom
 */

// Grey
$cc-grey:                           #a0a0a0 !default; // Brown grey
$cc-grey--light:                    #fafafa !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);

// Black
$cc-black:                          #302b2a !default;

// Aqua
$cc-aqua:                           #55c4bf !default; // Aqua marine
$cc-aqua--darker:                   #218f8a !default; // Viridian
$cc-aqua--dark:                     #0b5956 !default; // Spruce

// White
$cc-white:                          #fafafa !default;

// Purple
$cc-purple:                         #33f2d2 !default; // Amethyst
$cc-purple--hover:                  scale-color($cc-purple, $lightness: -30%);
$cc-purple--alt:                    #270E66 !default;
$cc-purple--dark:                   #0c041e !default;

// Red
$cc-red:                            #ff5151 !default; // Grapefruit

// Brown
$cc-brown:                          #c2ac7f !default;

// States
$success-text-color:                scale-color($green, $saturation: -50%) !default;
$success-bg-color:                  scale-color($green, $lightness: 90%, $saturation: -25%) !default;
$success-border-color:              scale-color($success-bg-color, $lightness: -5%) !default;

$info-text-color:                   scale-color($blue, $saturation: -50%) !default;
$info-bg-color:                     scale-color($blue, $lightness: 90%, $saturation: -25%) !default;
$info-border-color:                 scale-color($info-bg-color, $lightness: -5%) !default;

$warning-text-color:                scale-color($orange, $saturation: -50%) !default;
$warning-bg-color:                  scale-color($orange, $lightness: 90%, $saturation: -25%) !default;
$warning-border-color:              scale-color($warning-bg-color, $lightness: -5%) !default;

$danger-text-color:                 scale-color($red, $saturation: -50%) !default;
$danger-bg-color:                   scale-color($red, $lightness: 90%, $saturation: -25%) !default;
$danger-border-color:               scale-color($danger-bg-color, $lightness: -5%) !default;

// Lay-out
$text-color:                        $cc-black !default;
$background-color:                  $white !default;
$border-color:                      $cc-grey !default;
