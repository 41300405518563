.Slider {
    position: relative;
    overflow: hidden;
    z-index: 1;

    &--autoheight {

        &,
        .Slider-items {
            height: auto;
        }
    }

    &--blog {
        max-width: u(656px);
        position: relative;

        .MemberBlog-objects {
            @include grid(1fr, 1fr auto);
        }

        .MemberBlog-object {
            @include grid-area(1, 2, 1, 3);
        }

        .MemberBlog-date {
            @include font-size(18, false);
            @include grid-area(1, 2, 2, 3);
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-family: $font-oswald;
            text-transform: uppercase;
            letter-spacing: 0.3px;

            height: u(116.5px);
            width: u(170.2px);
        }

        .MemberBlog-icon {
            display: block;
            margin-right: u(18px);
            height: u(21.6px);
            width: u(19.8px);
        }

        .MemberBlog-body {
            margin-top: u(56px);
            max-width: u(520px);
            text-align: left;
        }

        .MemberBlog-title {
            @include font-size(22, false);
        }
    }

    &-items {
        display: flex;
    }

    &-item {
        position: relative;
        flex-shrink: 0;
    }

    &-object {

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &-body {
        align-self: center;
        color: $white;
        text-transform: uppercase;
        text-align: center;
    }
}

.Thumbnails {
    position: relative;
    overflow: hidden;
    user-select: none;
    pointer-events: none;

    &-items {
        display: flex;
        height: 100%;
        width: 100%;
    }

    &-item {
        background-color: $white;
        flex-shrink: 0;
        width: 656px;
        transition: opacity .2s;

        .Link {
            @include font-size(13, false);
            color: $cc-black;
            font-weight: 900;
            letter-spacing: 0.2px;
            max-width: u(165px);
        }

        &:not(.is-active) {

            .Thumbnails-object {
                opacity: .4;
            }
        }

        &.is-active {
            opacity: 0;
        }

        &.is-next {
            text-align: left;

            .Link {
                flex-direction: row-reverse;
            }

            .Icon {
                margin-left: 0;
                margin-right: u(16px);
            }
        }

        &.is-prev {
            text-align: right;

            .Thumbnails-body,
            .Thumbnails-object {
                margin-left: auto;
            }

            .Icon {
                transform: scale(-1, 1);
            }
        }
    }

    &-object,
    &-body {
        display: block;
        max-width: u(430px);
    }

    &-body {
        background-color: $white;
        padding: u(59px, 32px);
    }

    &-object {

        &.object-fit-polyfill {
            height: u(323px);
        }
    }
}

// Swiper
.Slider--fade {

    .Slider-item {
        pointer-events: none;
        transition-property: opacity;

        &.is-active {
            pointer-events: auto;
        }
    }

    &.Slider--free-mode {

        .Slider-item {
            transition-timing-function: ease-out;
        }
    }
}
// Navigation
.Slider-button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 10;

    body:not(.user-is-tabbing) &:focus {
        outline: none;
    }

    .Block--cases & {
        position: absolute;
        top: 50%;
        width: 27px;
        height: 44px;
        margin-top: -22px;
        z-index: 10;
        cursor: pointer;
        background-size: 27px 44px;
        background-position: center;
        background-repeat: no-repeat;
        transition: opacity .2s;
        opacity: .75;

        @include mq($until: $viewport--md - 1) {
            margin-top: u(-72px);
        }

        &--next {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
            right: u($spacing-unit);
            left: auto;
        }

        &--prev {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
            left: u($spacing-unit);
            right: auto;
        }

        &:hover {
            opacity: 1;
        }
    }

    &.is-disabled {
        opacity: .35;
        cursor: auto;
        pointer-events: none;
    }

    &--prev {

        &:hover {

            ~ .Thumbnails .is-prev .Icon {
                background-color: $cc-purple--hover;
            }
        }
    }

    &--next {

        &:hover {

            ~ .Thumbnails .is-next .Icon {
                background-color: $cc-purple--hover;
            }
        }
    }
}

// Pagination
.Slider-pagination {
    display: flex;
    justify-content: center;
    transform: translate3d(0,0,0);
    transition: .2s opacity;

    &--bullets {

        @include mq($from: $viewport--md) {
            position: absolute;
            right: u($spacing-unit);
            bottom: u($spacing-unit);
            left: u($spacing-unit);
            z-index: 10;
        }
    }

    &--fraction {
        margin-top: u($spacing-unit--xs);

        .HomePage & {
            color: $white;

            position: absolute;
            right: u($spacing-unit);
            bottom: u($spacing-unit);
            z-index: 10;
        }
    }

    &--progressbar {
        background: rgba($black, .25);
        height: 4px;
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;

        .HomePage & {
            top: auto;
            bottom: 0;
        }
    }
}

.Slider-pagination-item {

    .is-clickable & {
        cursor: pointer;
    }

    .Slider-pagination--bullets & {
        background-color: $cc-black;
        border-radius: 100%;
        opacity: .16;
        margin-right: u(3px);
        margin-left: u(3px);
        transition: opacity .2s;
        height: u(10px);
        width: u(10px);

        &:hover {
            opacity: .32;
        }

        &.is-active {
            opacity: 1;
        }

        body:not(.user-is-tabbing) &:focus {
            outline: none;
        }
    }

    .Slider-pagination--progressbar & {
        background-color: $cc-aqua;
        height: 100%;
        transform: scale(0);
        transform-origin: left top;
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;
    }
}
