.Blog {

    &Page {
        background-color: $cc-grey--light;

        .Modal {
            padding: 0;
            max-width: u(1000);
            width: 100%;

            @include mq($from: $viewport--md) {

                &-inner {
                    display: flex !important;
                }
            }

            &-object {
                width: (100% / 1000 * 355);

                @include mq($until: $viewport--md - 1) {
                    display: none;
                }
            }

            &-body {
                flex-grow: 1;
                padding: u($spacing-unit--lg);

                @include mq($from: $viewport--md) {
                    padding: u(80);
                }
            }

            &-image {
                height: 100%;
                object-fit: cover;
            }

            &-header {
                display: flex;
                flex-direction: column-reverse;
            }

            .Form {

                &-label,
                &-field--input {
                    @include font-size(16, false);
                    font-weight: 300;
                }

                &-field--input {
                    padding-top: u($spacing-unit);
                    padding-bottom: u($spacing-unit--xs);
                    position: relative;
                    z-index: 1;

                    &:focus {
                        border-bottom-color: $cc-purple;
                        color: $cc-purple;
                    }
                }

                &-item--action {
                    margin-top: u($spacing-unit--lg);
                }

                .Grid + .Grid {
                    padding-top: u($spacing-unit--sm);
                }
            }
        }
    }

    &DetailPage {

        .Columns-title {
            margin-bottom: u($spacing-unit--lg);

            em {
                color: $cc-aqua;
                font-weight: 300;
            }

            @include mq($from: $viewport--lg) {
                margin-bottom: u(120px);
            }
        }

        .Block + .Block {

            @include mq($from: $viewport--md) {
                margin-top: u(90);
            }
        }

        .is-reverse .Media {
            text-align: right;
        }

        .Media-object--video {
            max-width: u(930);
            margin-right: auto;
            margin-left: auto;
        }

        .Block--imageText {

            .Grid-cell:last-child {
                align-self: center;
            }
        }

        .Slider-image {
            width: auto;
            max-height: u(560);
        }

        .Slider {
            position: relative;

            &-item {
                width: 100%;
            }

            &-button {
                position: absolute;
                top: 50%;
                z-index: 10;

                background-color: $cc-purple;
                color: $white;
                box-shadow: 0 8px 13px 0 rgba($cc-purple, 0.3);
                transition: background-color .2s;

                margin-top: u(-27.5px);
                cursor: pointer;
                background-position: center;
                background-repeat: no-repeat;
                height: u(55);
                width: u(55);

                &-icon {
                    fill: $white;
                    height: u(17);
                    width: u(22);
                }

                &.is-disabled {
                    cursor: auto;
                    opacity: 0.35;
                    pointer-events: none;
                }

                &.is-locked {
                    display: none;
                }

                &--prev,
                .Slider--rtl &--next {
                    right: auto;
                    left: 0;

                    .Slider-button-icon {
                        transform: scale(-1, 1);
                    }
                }

                &--next,
                .Slider--rtl &--prev {
                    right: 0;
                    left: auto;
                }
            }
        }

        .Block--quote {
            color: $cc-aqua;
            text-align: center;

            > .Container {
                padding-top: u($spacing-unit--lg);
                padding-bottom: u($spacing-unit--lg);

                @include mq($from: $viewport--md) {
                    padding-top: u(60);
                    padding-bottom: u(60);
                }
            }

            &::before,
            &::after {
                background-color: $cc-aqua;
                content: '';
                display: block;
                height: 2px;
                max-width: u(120);
                width: 100%;
                margin-right: auto;
                margin-left: auto;
            }
        }

        .Quote-title {
            @include font-responsive($viewport--sm, $viewport--lg, 24, 33);
            line-height: 1.25;
        }
    }

    &-header {
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
    }

    &-title {
        @include font-responsive($viewport--sm, $viewport--md, 48, 72);
        font-weight: 300;
        letter-spacing: u(1.13);
        margin-top: u($spacing-unit--xs);
        margin-bottom: 0;

        .Main > .Container & {
            max-width: none;
        }
    }

    &-subtitle {
        @include font-responsive($viewport--sm, $viewport--md, 18, 24);
        color: $cc-aqua;
        font-family: $font-oswald;
        font-weight: 500;
        letter-spacing: u(9.23);
        text-transform: uppercase;
    }
}

.Articles {
    margin-top: u($spacing-unit--lg * -1);
    margin-left: u($spacing-unit--lg * -1);

    @include mq($from: $viewport--sm) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        &.is-unloaded,
        &.is-unloaded .Article {
            opacity: 0;
        }

        &-status {
            display: none;
        }
    }
}

.Article {
    @include grid(1fr, auto auto);
    padding-top: u($spacing-unit--lg);
    padding-left: u($spacing-unit--lg);

    .Icon--ext {
        @include grid-area(1, 2, 1, 2);
        align-self: start;
        justify-self: end;
        background-color: $cc-aqua;
        pointer-events: none;
        height: u(40px);
        width: u(40px);
        position: relative;
        z-index: 1;;
    }

    @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
        width: (100% / 2);
    }

    @include mq($from: $viewport--lg) {
        width: (100% / 3);
    }

    &-link {
        @include grid-area(1, 2, 1, 2);
        display: block;
        color: currentColor;
        text-decoration: none;
    }

    &-object {
        background-color: $cc-purple;
        display: block;
    }

    &-image {
        transition: opacity 200ms;
        width: 100%;

        .Article-link:hover & {
            opacity: .4;
        }
    }

    &-date {
        @include grid-area(1, 2, 1, 2);
        align-self: end;
        justify-self: start;
        background-color: $white;
        position: relative;
        z-index: 1;
        pointer-events: none;

        @include font-size(15, false);
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: $font-oswald;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        height: u(60);
        // padding-right: u($spacing-unit--lg);
        // padding-left: u($spacing-unit--lg);
        width: u(135);

        .Icon {
            margin-right: u($spacing-unit--sm);
            height: 1em;
            width: 1em;
        }
    }

    &-body {
        @include grid-area(1, 2, 2, 3);
        background-color: $white;
        padding: u($spacing-unit, $spacing-unit--lg);
    }

    &-header {
        display: flex;
        flex-direction: column-reverse;
    }

    &-category {
        @include font-size(13, false);
        color: $cc-grey;
        display: block;
        font-weight: 600;
        letter-spacing: u(0.2);
        text-transform: uppercase;
        margin-bottom: u($spacing-unit--xs);
    }

    &-title {
        margin-bottom: u($spacing-unit--sm * 1.5);
    }
}

