/* Nav */
.Nav {

    // Main
    &--main {
        @include font-size(15, false);
        font-family: $font-oswald;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        margin-left: u($spacing-unit);

        display: flex;
        align-items: center;
        position: relative;

        .Nav-listToggle {
            color: currentColor;
            cursor: pointer;
            height: u(22px);
            width: u(22px);

            display: flex;
            order: 2;
            flex-shrink: 0;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: u($spacing-unit);

            svg {
                height: 16px;
                width: 20px;

                path {
                    transform: rotate(0deg);
                    transition: transform .2s, opacity .2s;

                    &:nth-child(1) { transform-origin: 2px 6px; }
                    &:nth-child(3) { transform-origin: 2px 12px; }
                }
            }

            .show-nav & {

                path {
                    &:nth-child(1) { transform: rotate(45deg); }
                    &:nth-child(2) { opacity: 0; }
                    &:nth-child(3) { transform: rotate(-45deg); }
                }
            }
        }

        .Nav-list {

            &.is-priority {

                &:not(.is-toggled) {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            &:not(.is-toggled) {
                height: u(22px);
                justify-content: flex-end;
                overflow: hidden;

                @include mq($until: $viewport--sm - 1) {
                    display: none;
                }

                .Nav-link {
                    height: u(22px);
                }
            }

            &.is-toggled {
                background-color: $cc-aqua;
                flex-direction: column;
                min-width: u(200px);
                transition: opacity .2s;

                position: absolute;
                top: 100%;
                right: 0;
                margin-top: u($spacing-unit--sm);

                .Nav-link {
                    color: $white;
                    display: block;
                    padding: u($spacing-unit--xs, $spacing-unit--sm);
                    text-align: left;

                    &.is-active {
                        // background-color: $cc-blue--hover;
                        color: $white;
                    }
                }
            }
        }

        &:not(.is-toggled) {

            .Nav-item {

                + .Nav-item {
                    margin-left: u(52px);
                }
            }
        }

        .Nav-link {

            &,
            &:active,
            &:focus {
                color: currentColor;
            }

            &:hover,
            &.is-active {
                color: $cc-aqua;
            }
        }
    }

    // Address
    &--address {
        flex-grow: 1;

        .Nav-list {
            max-width: u(630px);
            justify-content: space-between;
            width: 100%;

            margin-top: u($spacing-unit--sm * -1);
            margin-left: u($spacing-unit * -1);
        }

        .Nav-item {
            padding-top: u($spacing-unit--sm);
            padding-left: u($spacing-unit);
        }

        .Nav-link {
            display: flex;
            align-items: center;

            &,
            &:hover,
            &:active,
            &:focus {
                color: currentColor;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .Icon {
            margin-right: u(15px);
            height: u(16px);
            width: u(15px);

            svg {
                display: block;
                height: 100%;
                width: 100%;
            }
        }
    }

    // Social
    &--socialMedia {

        .Nav-list {
            margin-left: u(-8px);
        }

        .Nav-item {
            padding-left: u(8px);
        }

        .Nav-link {
            background-color: $cc-aqua;
            border-radius: 100%;
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: background-color .2s;
            height: u(32px);
            width: u(32px);

            &:hover {
                background-color: $cc-aqua--darker;
            }

            svg {
                fill: currentColor;
                height: u(16px);
            }
        }
    }

    // Filter
    &--filter {
        @include font-size(13.1, false);
        font-family: $font-oswald;
        font-weight: 500;
        text-transform: uppercase;
        color: $cc-black;

        .Nav-list {
            justify-content: center;
            margin-top: u($spacing-unit--sm * -1);
            margin-left: u($spacing-unit--sm * -1);
        }

        .Nav-item {
            padding-top: u($spacing-unit--sm);
            padding-left: u($spacing-unit--sm);
        }

        .Nav-link {
            border: 1px solid $cc-black;
            color: $cc-black;
            display: flex;
            align-items: center;
            padding: u($spacing-unit--xs, $spacing-unit--sm);
            line-height: 1.6;

            &:hover,
            &:focus,
            &:active,
            &.is-active {
                background-color: $cc-black;
                color: $white;
            }
        }

        .Nav-icon {
            display: block;
            height: u(17px);
            margin-right: u($spacing-unit--sm);

            svg {
                display: block;
                height: 100%;
                width: 100%;
            }

            svg, path {
                fill: currentColor;
            }
        }
    }

    // Team
    &--team {
        @include font-size(13, false);
        font-weight: 700;
        letter-spacing: .2px;

        // background-color: $cc-grey--light;
        position: relative;
        padding-top: u(28px);
        padding-bottom: u(28px);

        @include mq($from: $viewport--lg) {
            margin-top: u(107px - 20px);
        }

        &::before {
            background-color: $cc-grey--light;
            content: '';
            width: 100vw;

            position: absolute;
            top: 0;
            left: u($structure-responsive-gutter--r * -1);
            bottom: 0;
            z-index: -1;

            @include mq($from: $viewport--md) {
                left: u($structure-gutter--r * -1);
            }

            @include mq($from: $structure-width--md) {
                left: calc((100vw - #{u($structure-width--md)}) / -2);
            }
        }

        .Nav-list {
            align-items: center;
            margin-left: u(-25px);

            @include mq($until: $viewport--lg - 1) {
                justify-content: center;
            }
        }

        .Nav-item {
            margin-left: u(25px);

            &--prev {

                .Icon {
                    margin-left: u(16px);
                    transform: scale(-1, 1);
                }
            }

            &--next {

                .Icon {
                    margin-right: u(16px);
                }
            }

            &--overview {

                svg {
                    fill: $cc-purple;
                    display: block;
                    height: u(14px);
                    width: u(18px);
                    transition: fill .2s;
                }

                &:hover,
                &:active,
                &:focus {

                    svg {
                        fill: $cc-purple--hover;
                    }
                }
            }
        }

        .Icon {
            background-color: $cc-purple;
            color: $white;
            box-shadow: 0 8px 13px 0 rgba($cc-purple, 0.3);
            transition: background-color .2s;
        }

        .Nav-link {
            display: flex;

            &:hover,
            &:active,
            &:focus {

                .Icon {
                    background-color: $cc-purple--hover;
                }
            }
        }

        .Nav-member {
            line-height: (18 / 13);

            &, span {
                display: block;
            }

            @include mq($until: $viewport--lg - 1) {
                display: none;
            }
        }

        .Nav-name {
            color: $cc-purple;
        }

        .Nav-function {
            color: $cc-grey;
        }
    }
}
