/* Footer */
.Footer {

    &-bottom {
        @include font-size(15, false);
        background-color: $cc-black;
        color: $white;
        font-weight: 700;
        padding-top: u(30px);
        padding-bottom: u(30px);

        > .Container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin-top: u($spacing-unit * -1);

            > .Nav {
                padding-top: u($spacing-unit);
            }
        }
    }
}