/* ClipPath */
.with-clipPath {
    position: relative;

    &::before {
        background-color: $white;
        clip-path: ellipse(50% 100% at 50% 100%);
        content: '';
        height: 24px;

        position: absolute;
        right: u(-24px);
        bottom: 0;
        left: u(-24px);
        z-index: 2;
    }
}

// PinItem
.Pin-item {

    @include mq($from: $viewport--md) {
        float: right;
    }
}

// Link
.Link {
    @include font-size(15, false);
    display: inline-flex;
    align-items: center;
    font-weight: 900;
    letter-spacing: 0.2px;
    text-decoration: none;
    text-transform: uppercase;

    &--primary {
        color: $cc-purple;

        .Icon {
            background-color: $cc-purple;
            color: $white;
            box-shadow: 0 8px 13px 0 rgba($cc-purple, 0.3);
            transition: background-color .2s;
        }

        &:hover,
        &:active,
        &:focus {
            color: $cc-purple--hover;

            .Icon {
                background-color: $cc-purple--hover;
                // box-shadow: 0 8px 13px 0 rgba($cc-purple--hover, 0.3);
            }
        }
    }

    &--small {
        @include font-size(13, false);

        .Icon--arrow {
            height: u(24px);
            width: u(24px);

            svg {
                height: u(8px);
                width: u(10px);
            }
        }
    }

    &--apply {
        color: $cc-purple;
        font-weight: 700;
        text-transform: none;
    }
}

.Icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: u(32px);
    width: u(32px);

    .Link & {
        margin-left: u(16px);
    }

    &--arrow {

        svg {
            height: u(10px);
            width: u(15px);
        }
    }
}

// Banner
.Banner {
    @include grid(100%, #{u($structure-header-height)} 1fr);

    &-object {
        @include grid-area(1, 2, 1, 3);
        background-color: $cc-black;
        position: relative;

        &.object-fit-polyfill {
            height: calc(640 / 1440 * 100vw);
            max-height: u(640px);
            min-height: 100%;
        }

        .FlexEmbed,
        img {
            max-height: u(640px);
            height: 100%;
        }

        img,
        video {
            object-fit: cover;
            width: 100%;

            [data-type="small"] &,
            [data-type="service"] &,
            [data-type="contact"] & {
                opacity: .3;
            }
        }
    }

    &-body {
        @include grid-area(1, 2, 2, 3);
        align-self: center;
        position: relative;

        z-index: 1;
        padding: u($spacing-unit--lg, 0);

        body:not(.has-banner) & {

            .Container {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .has-banner & {
            top: u(-20px);
        }
    }

    &-innerBody {
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .Service-subtitle {
            @include font-size(24, false);
            text-shadow: 0 2px 24px rgba(0, 0, 0, 0.7);
            letter-spacing: 9.2px;
            margin-top: u(6px);
        }
    }

    &-title {
        color: $white;
        margin-bottom: 0;
        font-weight: 300;

        .has-banner & {
            @include font-responsive($viewport--xs, $viewport--md, $heading-size-mega * .65, $heading-size-mega);
            max-width: u(558px);
            letter-spacing: 1px;
            line-height: 1.17;
            margin-bottom: u($spacing-unit--lg);

            small {
                font-size: 50%;
                font-weight: inherit;
            }

            @include mq($from: $viewport--md) {
                margin-bottom: u(60px);
            }
        }

        body:not(.has-banner) & {
            @include font-responsive($viewport--xs, $viewport--md, 72 * .65, 72);
            letter-spacing: 1.1px;
            line-height: (91 / 72);
        }

        @include mq($until: $viewport--md - 1) {

            br {
                display: none;
            }
        }
    }

    &-icon {
        position: relative;
        margin-bottom: 8.5%;

        &Item {
            color: $white;
            height: u(80.4px);
            width: u(80.4px);

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &, path {

                &[fill]:not([fill="none"]) {
                    fill: currentColor !important;
                }

                &[stroke]:not([stroke="none"]) {
                    stroke: currentColor !important;
                }
            }
        }

        &Bg {
            height: 165px;
            width: 165px;
        }

        &Lines {
            position: absolute;
            top: 10%;
            left: 50%;
            z-index: -1;
            height: calc(130vw / 486 * 103);
            width: 130vw;
            margin-left: -65vw;
        }
    }

    &-lines {
        position: absolute;
        bottom: -5%;
        left: 40%;
    }
}

// Cases
.Cases {
    background-color: $cc-grey--light;
    padding-top: u(120px);
    padding-bottom: u(120px);

    .Grid-cell {
        display: flex;
        flex-direction: column;

        > .Case-item {
            flex-grow: 1;
        }
    }

    &-body {

        .Lead {
            max-width: u(480px);
        }
    }

    &-object {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u(75px);
        }

        .Slider {

            &-item {
                @include grid(100%, 1fr);
                background-color: $white;
                user-select: none;
                pointer-events: none;
            }

            &-object {
                @include grid-area(1, 2, 1, 2);
                position: relative;
                display: block;

                // &.object-fit-polyfill {
                //     height: calc(640 / 1440 * 100vw);
                //     max-height: u(640px);
                //     min-height: u(360px);
                // }

                // img {
                //     max-height: u(640px);
                //     min-height: u(360px);
                //     height: 100%;
                // }

                // &::after {
                //     background-image: linear-gradient(to bottom, rgba($black, 0), $black);
                //     content: '';
                //     height: u(230px);

                //     position: absolute;
                //     right: 0;
                //     bottom: 0;
                //     left: 0;
                //     z-index: 1;
                // }
            }

            &-body {
                @include grid-area(1, 2, 2, 3);
                align-self: end;
                text-align: right;

                @include mq($from: $viewport--sm) {
                    @include grid-area(1, 2, 1, 2);
                }

                .Link {
                    color: $cc-aqua;
                    // pointer-events: auto;
                    margin-top: u($spacing-unit);
                    margin-bottom: u($spacing-unit);

                    @include mq($from: $viewport--sm) {
                        margin-top: 0;
                        margin-bottom: u($spacing-unit--lg);
                    }

                    position: relative;
                    z-index: 2;

                    .Icon {
                        background-color: $cc-aqua;
                        color: $white;
                        transition: background-color .2s, color .2s;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        color: $cc-aqua--darker;

                        .Icon {
                            background-color: $cc-aqua--darker;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    &-link {
        text-align: center;
        margin-top: u($spacing-unit--lg);
    }
}

.Case {

    &-item {
        @include grid(100%, 100%);
        box-shadow: 0 22px 56px 0 rgba($black, 0.16);
        text-decoration: none;

        &:not(:hover) {

            .Case-body {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &-categories {
        display: flex;
    }

    &-objects {
        @include grid-area(1, 2, 1, 2);
        @include grid(auto 1fr, 1fr auto);

        .Case-categories {
            @include grid-area(1, 2, 2, 3);
            background-color: $white;

            padding: u(14px, 18px);
            position: relative;
            z-index: 1;
        }
    }

    &-object {
        @include grid-area(1, 3, 1, 3);
        display: block;

        &.object-fit-polyfill {
            height: u(260px);
        }
    }

    &-image {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &-icon {
        height: u(24px);

        & + .Case-icon {
            margin-left: u($spacing-unit--sm)
        }

        svg {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    &-title {
        @include font-size(20, false);
        font-weight: 700;
        letter-spacing: 0.3px;
        margin-bottom: u(8px);
    }

    &-body {
        @include font-size(15, false);
        @include grid-area(1, 2, 1, 2);
        background-color: $cc-purple;
        color: $white;
        font-weight: 400;
        line-height: 1.47;
        letter-spacing: 0.2px;
        padding: u(24px, 48px);
        position: relative;
        z-index: 2;

        > * {
            max-width: 100%;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: opacity .2s, visibility .2s;

        .Case {

            &-categories {
                margin-top: auto;
                margin-bottom: u(12px);

                @media screen and (min-width:0\0) and (min-resolution:+72dpi), \0screen\,screen\9 {
                    margin-top: 0;
                }
            }

            &-icon {
                height: 24px;

                svg, path {
                    fill: currentColor;
                }
            }

            &-link {
                margin-top: auto;
                fill: currentColor;
                height: u(7px);
                width: u(9px);

                @media screen and (min-width:0\0) and (min-resolution:+72dpi), \0screen\,screen\9 {
                    margin-top: 0;
                }
            }
        }


        > :last-child {
            margin-bottom: 0;
        }
    }
}

// Brands
.Brands {

    &-title {

        strong {
            font-weight: 900;
        }
    }

    &-object {
        max-width: u(800px);
        margin-top: u($spacing-unit * 3);
        margin-right: auto;
        margin-bottom: u($spacing-unit--lg);
        margin-left: auto;
    }
}

.Brand {

    &-object {
        transition: filter .2s, opacity .2s;

        &:not(:hover) {
            filter: grayscale(100%);
            opacity: .55;
        }

        &, img {
            display: block;
        }

        img {
            max-height: u(80px);
            width: auto;
        }
    }
}

// Services
.Service {

    &-item {
        @include grid(100%, 100%);
        box-shadow: 0 30px 50px 0 rgba($black, 0.2);
        padding: u($spacing-unit);
        text-decoration: none;

        @include mq($from: $viewport--md) {
            padding: u(25px);
        }

        &:hover {

            .Service-image {
                transform: scale(1.1);
            }
        }
    }

    &-object {
        @include grid-area(1,2,1,2);
        background-color: $black;
        box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;

        &.object-fit-polyfill {
            height: u(306px);
        }
    }

    &-image {
        opacity: .3;
        transition: transform .2s;
        width: 100%;
    }

    &-body {
        @include grid-area(1,2,1,2);
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        z-index: 1;
        align-self: center;
        justify-self: center;
    }

    &-title {
        @include font-responsive($viewport--xs, $viewport--md, 40 * .75, 40);
        font-weight: 300;
        margin-bottom: u(2px);
        letter-spacing: 0;
        text-transform: none;
    }

    &-subtitle {
        @include font-size(13, false);
        font-family: $font-oswald;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 0;
    }
}

// Related
.Related {

    &-item {
        @include grid(100%, 1fr auto);
        text-decoration: none;
        position: relative;
    }

    &-items {
        margin-top: u($spacing-unit--lg);
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u(80px);
            margin-bottom: u(56px);
        }
    }

    &Item {

        &-body {
            @include grid-area(1, 2, 2, 3);
            color: $white;
            display: flex;
            flex-direction: column-reverse;
            padding: u(26px, 28px);
            position: relative;
            z-index: 1;
        }

        &-object {
            @include grid-area(1, 2, 1, 3);
            box-shadow: 0 22px 56px 0 rgba($black, 0.063);

            &.object-fit-polyfill {
                height: u(260px);
            }

            &::after {
                background-image: linear-gradient(to bottom, rgba($black, 0), rgba($black, 0.7));
                content: '';
                height: 115px;
                width: 100%;

                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
            }
        }

        &-image {
            width: 100%;
        }

        &-title {
            @include font-size(18, false);
            font-weight: 600;
            letter-spacing: 0.3px;
            margin-bottom: 0;
        }

        &-category {
            @include font-size(13, false);
            font-weight: 900;
            letter-spacing: 0.2px;
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }
}

// Filter
.Filter {
    background-color: $white;
    padding-top: u(24px);
    padding-bottom: u(24px);
}

// Downloads
.Downloads {
    display: flex;
    flex-wrap: wrap;
    margin-top: u($spacing-unit--sm * -1);
    padding-top: u($spacing-unit--lg);
    margin-left: u(-30px);

    @include mq($from: $viewport--md) {
        padding-top: u(120px);
    }
}

.Download {

    &-item {
        display: block;
        height: u(55px);
        margin-top: u($spacing-unit--sm);
        margin-left: u(30px);
    }

    &-button {
        height: 100%;
        width: 100%;
    }
}

// Media
.Media {

    &-object {

        &:not(:last-child) {
            margin-bottom: u($spacing-unit--lg);
        }

        &:not(:first-child) {
            margin-top: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--md) {

            &--right,
            &--left {
                max-width: u(600px);
                width: 50vw;

                img {
                    max-height: u(700px);
                }

                & + h2:not(:first-child),
                & + .Bravo:not(:first-child) {
                    margin-top: 0;
                }

                ~ blockquote,
                ~ dl,
                ~ dd,
                ~ h1,
                ~ h2,
                ~ h3,
                ~ p,
                ~ .Lead > p {
                    max-width: none !important;
                }
            }

            &--right {
                margin-bottom: u($spacing-unit);
                margin-left: u($spacing-unit--lg);
                float: right;

                .CasesDetailPage .Container--md & {
                    margin-right: u($structure-responsive-gutter--r * -1);
                    transform: translateX(#{u(95px)});

                    @include mq($from: $viewport--md) {
                        margin-right: u($structure-gutter--r * -1);
                    }

                    @include mq($from: $structure-width--md + $structure-gutter--r + $structure-gutter--l) {
                        margin-right: calc((100vw - #{u($structure-width--md)}) / -2);
                    }
                }
            }

            &--left {
                margin-bottom: u($spacing-unit);
                margin-right: u($spacing-unit--lg);
                float: left;

                .CasesDetailPage .Container--md & {
                    margin-left: u($structure-responsive-gutter--r * -1);
                    transform: translateX(#{u(-95px)});

                    @include mq($from: $viewport--md) {
                        margin-left: u($structure-gutter--r * -1);
                    }

                    @include mq($from: $structure-width--md + $structure-gutter--r + $structure-gutter--l) {
                        margin-left: calc((100vw - #{u($structure-width--md)}) / -2);
                    }
                }
            }
        }
    }
}

// Gallery
.Gallery {

    &-lines {
        position: absolute;
        top: 10%;
        left: 50%;
        max-height: 100%;
        width: 130vw;
        margin-left: -65vw;
        // transform: rotate(10deg);

        path {
            stroke: $cc-purple--alt;
        }
    }

    &-image {
        width: 100%;
    }

    &-object {

        &.object-fit-polyfill {
            height: u(260px);
        }
    }

    &-link {
        @include grid(1fr, 1fr);

        .Gallery-object,
        .Play {
            @include grid-area(1, 2, 1, 2);
        }

        .Play {
            align-self: center;
            justify-self: center;
            position: relative;
            z-index: 1;

            &-icon {
                transition: color 200ms;
                color: rgba($white, .5);
                height: u(39);
                width: u(36);
            }
        }

        &:hover {

            .Play-icon {
                color: rgba($white, .75);
            }
        }
    }
}

// Lead
// .Lead {

//     .AboutUsPage .Main > .Container & {
//         column-count: 2;
//         column-gap: u(76px);
//         column-width: u(200px);

//         p {
//             break-inside: avoid;
//         }
//     }
// }

// Child
.Child {
    display: block;
    text-decoration: none;

    &,
    &:hover,
    &:active,
    &:focus {
        color: currentColor;
    }

    &:hover {

        .Child-more {
            background-color: $white;
            color: $cc-black;
        }
    }

    &-object {
        @include grid(1fr auto, 1fr);
        color: $white;
        padding: u($spacing-unit);

        > * {
            align-self: end;
        }

        @include mq($from: $viewport--lg) {
            padding: u(48px);
        }
    }

    &Item {

        &-object {
            @include grid-area(1, 3, 1, 2);
            background-color: $cc-black;
            margin: u($spacing-unit * -1);
            position: relative;

            &.object-fit-polyfill {
                height: u(289px);
            }

            @include mq($from: $viewport--lg) {
                margin: u(-48px);
            }
        }

        &-image {
            opacity: .5;
            width: 100%;
        }
    }

    &-title {
        @include font-responsive($viewport--xs, $viewport--md, 40 * .75, 40);
        @include grid-area(1, 2, 1, 2);
        max-width: u(250px);
        position: relative;
        z-index: 1;

        @include mq($from: $viewport--md, $until: $viewport--xl - 1) {
            @include font-responsive($viewport--md, $viewport--xl, 30, 40);
            max-width: u(225px);
        }
    }

    &-more {
        @include grid-area(2, 3, 1, 2);
        border: 3px solid $white;
        height: u(77px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: u(84px);

        position: relative;
        z-index: 1;
        transition: background-color .2s, color .2s;

        svg {
            display: block;
            fill: currentColor;
            height: u($spacing-unit--sm);
            width: u(13px);
        }
    }

    &-body {
        padding: u($spacing-unit--lg, $spacing-unit);

        @include mq($from: $viewport--lg) {
            padding: u(40px, 48px);
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}

// Column
.Column {

    @include mq($from: $viewport--lg) {
        max-width: 35vw;
        width: u(430px);

        .JobsPage & {
            max-width: 25vw;
            width: u(280px);
        }
    }

    &-header {
        margin-bottom: u(16px);
    }

    &-object {
        display: block;
        height: u(48px);
        margin-bottom: u($spacing-unit--lg);

        svg {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    &--default {

        .Column-body {

            &::before {
                @include font-size(13, false);
                content: "Stap " counter(step);
                color: $cc-purple;
                display: block;
                font-weight: 700;
                letter-spacing: 2px;
                line-height: (17 / 13);
                text-transform: uppercase;
                margin-bottom: u(8px);
            }
        }

        .Column-object {
            fill: $cc-purple;
            width: u(48px);
        }
    }

    &--team {

        .Column-object {
            display: block;
            height: u(80px);
            margin-bottom: u($spacing-unit--lg);
        }
    }

    &--jobs {

        .Column-object {
            fill: $cc-purple;
            width: u(48px);
        }
    }
}

// Subheader
.Subheader {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: u($spacing-unit--lg);

    &-title {
        @include font-size(40, false);
        margin-top: u(8px);
        margin-bottom: 0;
    }

    &-subtitle {
        @include font-size(13, false);
        color: $cc-grey;
        display: block;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
}
// Between
.Between {
    background-color: $cc-aqua;
    color: $white;
    padding-top: u(56px);
    padding-bottom: u(56px);
    text-align: center;

    &-body {
        max-width: u(460px);
        margin-right: auto;
        margin-left: auto;
    }
}

// Members
.Teams {
    text-align: center;

    .Between + & {
        margin-top: u(84px);
    }
}

.Team {

    & + & {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u(120px);
        }
    }

    &-body {
        max-width: u(666px);
        margin-right: auto;
        margin-left: auto;
    }

    &-object {
        margin-top: u(64px);
    }
}

.Members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: u(-32px);
    margin-left: u(-32px);

    .Member {
        margin-top: u(32px);
        margin-left: u(32px);

        flex-shrink: 0;
        text-decoration: none;
        width: u(218px);

        &,
        &:hover,
        &:focus,
        &:active {
            color: currentColor;
        }

        &-object {
            @include grid(37px 144px 37px, 47px 1fr 27px);
            display: grid;
            background-color: rgba($cc-aqua, .1);
            border-radius: 100%;
            height: u(218px);
            max-width: u(218px);
            margin-bottom: u(28px);
            transition: background-color .2s;

            &::before {
                @include grid-area(2, 3, 2, 3);
                background-color: $cc-aqua;
                box-shadow: inset 0 1px 23px 0 rgba(0, 0, 0, 0.3);
                border-radius: 100%;
                content: '';
                display: block;
            }
        }

        &:hover {

            .Member-object {
                background-color: rgba($cc-aqua, .2);
            }
        }

        &-image {
            @include grid-area(2, 3, 1, 3);
            align-self: end;
            border-radius: 0 0 144px 144px;
            height: u(166px);
            width: u(218px);
        }

        &-title {
            font-weight: 400;

            > small {
                font-weight: 700;
                display: block;
            }
        }
    }
}

.Member {

    &-title {

        .TeamDetailPage & {
            @include font-responsive($viewport--xs, $viewport--md, 80 * .75, 80);
            font-weight: 700;
            letter-spacing: 1.3px;
            margin-bottom: u(72px);

            > small {
                display: block;
                font-size: 50%;
                font-weight: inherit;
                letter-spacing: 0.6px;
            }
        }
    }

    &-subtitle {
        color: currentColor;
    }

    &-about {
        max-width: u(550px);
        margin-bottom: auto;

        &Item {
            // max-width: u(205px);

            & + & {
                margin-top: u($spacing-unit--lg);
            }
        }
    }

    &-object {

        .TeamDetailPage & {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;

            &::before {
                background-image: url(#{$path-img}lines--bg.svg), linear-gradient(to bottom, #64cec9, #44a3b6);
                background-repeat: no-repeat, repeat;
                background-position: bottom center, 50%;
                background-size: cover, auto;
                content: '';

                position: absolute;
                top: u($spacing-unit--lg * -1);
                right: u($structure-responsive-gutter--r * -1);
                bottom: 0;
                left: 47.5%;
                z-index: -1;

                @include mq($from: $viewport--md) {
                    right: u($structure-gutter--r * -1);
                }

                @include mq($from: $structure-width--md) {
                    right: calc((100vw - #{u($structure-width--md)}) / -2);
                }

                @include mq($from: $viewport--lg) {
                    top: u(-86px);
                }
            }
        }
    }

    &-image {

        .TeamDetailPage & {
            margin-top: auto;
        }
    }
}

.MemberInfo {
    @include grid(1fr auto, 1fr auto auto);

    &-body {
        @include grid-area(1, 3, 1, 2);

        @include mq($from: $viewport--lg) {
            @include grid-area(1, 2, 1, 2);
        }
    }

    .Nav--team {
        @include grid-area(1, 3, 3, 4);

        @include mq($from: $viewport--lg) {
            @include grid-area(1, 2, 2, 4);
        }
    }

    .Member-object {
        @include grid-area(1, 3, 2, 3);

        @include mq($until: $viewport--lg - 1) {
            margin-top: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--lg) {
            @include grid-area(2, 3, 1, 4);
        }
    }
}

.MemberBlog {
    position: relative;
    margin-top: u($spacing-unit--lg);
    padding-right: u($structure-responsive-gutter--r);
    padding-left: u($structure-responsive-gutter--l);
    margin-right: auto;
    margin-left: auto;

    &-object {

        &.object-fit-polyfill {
            height: u(482px);
        }
    }

    @include mq($from: $viewport--lg) {
        @include grid(auto 656px auto, 1fr);
        max-width: u(2037px);
    }

    @include mq($until: $viewport--lg - 1) {

        .Slider {
            margin-right: auto;
            margin-left: auto;
        }
    }

    @include mq($from: $viewport--md) {
        margin-top: u(85px);
    }

    &::before {
        @include grid-area(2, 3, 1, 2);
        box-shadow: 0 22px 44px 0 rgba($black, 0.3);
        content: '';
        height: 0;
        padding-bottom: calc(100% / 4 * 3);
        max-width: u(656px);
        width: 100%;
        position: relative;
        z-index: 9;
        user-select: none;
        pointer-events: none;
    }

    .Slider {
        @include grid-area(2, 3, 1, 2);

        &-button {
            position: relative;
            z-index: 9;

            &--next {
                @include grid-area(3, 4, 1, 2);
            }

            &--prev {
                @include grid-area(1, 2, 1, 2);
            }
        }
    }

    .Thumbnails {
        @include grid-area(1, 4, 1, 2);
        position: relative;
        z-index: 10;
    }

    .Slider-button {
        flex-grow: 1;

        &--prev {
            order: -1;
        }
    }

    .Slider-button {
        position: static;
    }
}

.Jobs {
    max-width: u(590px);
}

.Job {

    + .Job {
        margin-top: u(56px);
    }
}

.Apply {

    &-title {
        @include font-responsive($viewport--xs, $viewport--md, 48 * .75, 48);
        margin-bottom: u(31px);
    }
}

.Visual {

    &-object {
        @include grid-area(1, 2, 1, 2);
        opacity: .8;

        &.object-fit-polyfill {
            height: calc(440 / 1440 * 100vw);
            max-height: u(440px);
        }
    }

    &-image {
        object-fit: cover;
        max-height: u(440px);
        height: 100%;
        width: 100%;
    }

    &-body {
        @include grid-area(1, 2, 1, 2);
        align-self: center;
        text-align: center;
        padding-top: u($spacing-unit--lg);
        padding-bottom: u($spacing-unit--lg);
    }

    &-title {
        @include font-responsive($viewport--xs, $viewport--md, 70 * .75, 70);
        color: $white;
        font-family: $font-oswald;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: 0;
    }
}

.List {

    &-title {
        max-width: u(300px);
        margin-bottom: u(30px);
    }

    &-body {
        @include font-size(18, false);
        font-weight: 600;
        line-height: 1.5;
    }

    &Body-item {

        & + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(72px);
            }
        }

        ul:not([class]) {
            column-count: 2;
            column-gap: u(72px);
            column-width: u(300px);
            max-width: none;

            > li {
                break-inside: avoid;
            }
        }
    }
}

.Buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: u($spacing-unit--sm * -1);
    margin-left: u(-24px);

    > .Button {
        margin-top: u($spacing-unit--sm);
        margin-left: u(24px);
    }
}

ul:not([class]) {
    list-style-type: none;
    padding-left: 0;
    max-width: u(440px);

    ul:not([class]) {
        margin-top: u($spacing-unit);
    }

    > li:not(:last-child) > ul:not([class]) {
        margin-bottom: u($spacing-unit);
    }

    > li {
        background-image: url(#{$path-img}icons/icon-triangle.svg);
        background-repeat: no-repeat;
        background-position: left u(9px);
        padding-left: u($spacing-unit);
        margin-bottom: 0;

        + li {
            margin-top: u($spacing-unit--sm);
        }
    }
}

.Contact {

    &-body {
        max-width: u(210px);
    }
}

.Maps {

    &-object {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--xl);
        }

        .FlexEmbed {
            max-height: u(470px);
        }
    }
}

// Mouse
.Mouse {
    align-items: center;
    border: 2px solid $cc-white;
    border-radius: u(10px);
    display: flex;
    flex-flow: column nowrap;
    height: u(35px);
    padding: u(5px, 0);
    width: u(20px);

    position: relative;
    margin: u(0, 24px, 24px, 0);
    z-index: 1;

    &::before {
        background-color: $cc-white;
        border-radius: 100%;
        content: '';
        display: block;
        height: 4px;
        width: 4px;
        animation-name: scroll;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
    }
}

@keyframes scroll {
    0% {
        opacity: 0
    }

    10% {
        transform: translateY(0);
        opacity: 1
    }

    100% {
        transform: translateY(10px);
        opacity: 0
    }
}

// PageTitle
.Page-title {
    margin-bottom: u(32px);
}

// CaseDetailPage
.CasesDetailPage {

    .Block--banner:not(.with-visual) {
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-bottom: u(72px);
        }
    }

    .Main > .Container {

        h3 {

            @include mq($from: $viewport--md) {
                @include font-size(24, false);
            }
        }
    }
}
