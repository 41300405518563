/**
 * Objects: FlexEmbed
 */

// Namespace
$flexEmbed-namespace:                   $namespace !default;

// Ratios
$flexEmbed-ratios: (
    "2by1": (2:1),
    "3by1": (3:1),
    "3by2": (3:2),
    "4by3": (4:3),
    "9by4": (9:4),
    "16by9": (16:9),
    "35by26": (35:26)
) !default;
