/* Blocks */
.Block {

    + .Block {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--lg * 2);
        }
    }

    &-header {
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-bottom: u($spacing-unit--xl);
        }
    }

    &:first-child {

        &:not(.Block--banner):not(.Block--member) {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(80px);
            }
        }
    }

    &:last-child {
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--lg) {
            margin-bottom: u(120px);
        }
    }

    &--banner {
        @include grid(100%, 0 1fr);
        position: relative;

        .has-banner & {
            @include grid(100%, #{u($structure-header-height)} 1fr);
        }

        &[data-type="home"] {

            .Banner-body {
                color: $white;
            }

            .Banner-title {
                margin-bottom: 0;

                + .Lead {
                    margin-top: u($spacing-unit--xs);
                }

                + .Button {
                    margin-top: u(60px);
                }
            }

            .Lead {
                @include font-responsive($viewport--xs, $viewport--md, 30 * .65, 30);

                + .Button {
                    margin-top: u(60px);
                }
            }
        }

        &[data-type="case"] {
            @include grid(100%, 1fr auto 0);

            &.with-visual {

                @include mq($from: $viewport--md) {
                    @include grid(100%, 1fr auto 170px);
                }
            }

            .Banner-object {
                @include grid-area(1, 2, 1, 3);

                + .Container {
                    @include grid-area(1, 2, 2, 4);
                }
            }

            .Banner-image {
                max-height: none;
            }

            .Banner-visual {
                position: relative;
                z-index: 2;
            }
            + .Container {
                padding-top: 0;
            }
        }

        &[data-type="small"] {
            overflow: hidden;

            .Banner-object.object-fit-polyfill {
                height: u(354px);
                max-height: none;
                min-height: 0;
            }

            .Banner-body {
                color: $white;

                > .Container {
                    align-items: flex-start;
                }

                .Lead {
                    max-width: u(590px);
                }
            }

            .Banner-title {
                @include font-responsive($viewport--xs, $viewport--md, 56 * .75, 56);
                font-weight: 200;
                margin-bottom: u(14px);
            }

            .Banner-image {
                max-height: u(354px);
            }
        }

        &[data-type="contact"] {
            overflow: hidden;

            .Banner-body {
                color: $white;
                text-align: center;

                .Lead {
                    max-width: u(590px);
                }
            }

            .Banner-title {
                @include font-responsive($viewport--xs, $viewport--md, 56 * .75, 56);
                font-weight: 200;
                margin-bottom: u(14px);

                strong {
                    font-weight: 700;
                }
            }

            .Banner-lines {
                top: 50%;
                left: 50%;
                bottom: auto;
                transform: translate(-50%, -65%);
                width: 130vw;
            }
        }

        .Mouse {
            @include grid-area(1, 2, 2, 3);
            align-self: end;
            justify-self: end;
        }
    }

    &--cases {

        .Block + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(115px);
            }
        }
    }

    &--brands {
        background-color: $cc-grey--light;
        text-align: center;
        padding-top: u($spacing-unit--lg);
        padding-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            padding-top: u(70px);
            padding-bottom: u(100px);

            & + .Block--cta {
                margin-top: 0;
            }
        }


        .Lead {
            max-width: u(660px);
            margin-right: auto;
            margin-left: auto;
        }
    }

    &--cta {
        text-align: center;
        box-shadow: 0 -1px 0 0 rgba($black, 0.1);
        padding-top: u($spacing-unit--lg);
        padding-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            padding-top: u(97px);
            padding-bottom: u(120px);
        }

        .Form {
            margin-right: auto;
            margin-left: auto;

            &-item--action .Form-controls {
                text-align: center !important;
            }
        }

        a.Button {
            margin-top: u($spacing-unit);

            @include mq($from: $viewport--md) {
                margin-top: u(36px);
            }
        }
    }

    &--services {

        .Block + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(198px);
            }
        }

        & + .Block {

            @include mq($from: $viewport--md) {
                margin-top: u(84px);
            }
        }
    }

    &--related {
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-bottom: u(120px);
        }

        .Block-title {
            margin-bottom: u(3px);
        }
    }

    &--gallery {
        background-color: $cc-purple--dark;
        padding-top: u(75px);
        padding-bottom: u(75px);

        &.with-clipPath {
            margin-top: u(24px);

            &::before {
                background-color: $cc-purple--dark;
                bottom: 100%;
            }
        }

        .Grid {
            position: relative;
            z-index: 1;
        }
    }

    &--columns {
        counter-reset: step;

        .Block + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(120px);
            }
        }

        .Columns-title {
            margin-bottom: u($spacing-unit--lg);

            em {
                color: $cc-aqua;
                font-weight: 300;
            }

            @include mq($from: $viewport--lg) {
                margin-bottom: u(120px);
            }
        }

        .Grid--withExtraLargeGutter {
            margin-left: u($spacing-unit--lg * -1);

            > .Grid-cell {
                padding-left: u($spacing-unit--lg);
            }
        }

        .Grid-cell {
            counter-increment: step;
        }
    }

    &--teamColumns {

        .Block + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(176px);
            }
        }

        .Columns-title {
            @include font-responsive($viewport--xs, $viewport--lg, 60, 90);
            color: $cc-aqua--dark;
            font-family: $font-oswald;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.11;
            transform: translateY(-9%);

            .Main > .Container & {
                max-width: u(450px);
            }
        }

        .Grid--withExtraLargeGutter {
            margin-top: u($spacing-unit--lg * -1);

            > .Grid-cell {
                padding-top: u($spacing-unit--lg);
            }

            @include mq($from: $viewport--md) {
                margin-top: u(-120px);

                > .Grid-cell {
                    padding-top: u(120px);
                }
            }
        }

        .Grid-cell {

            &:first-child {
                position: relative;

                @include mq($until: $viewport--md - 1) {
                    display: flex;
                    flex-direction: column;

                    .Column--team {
                        margin-top: auto;
                    }
                }

                &::before {
                    background-color: $cc-aqua;
                    content: '';

                    position: absolute;
                    top: 0;
                    right: u($structure-responsive-gutter--r * -1);
                    bottom: 50%;
                    left: u($structure-responsive-gutter--l * -1);
                    z-index: -1;

                    @include mq($from: $viewport--sm) {
                        top: u(-176px + $grid-xl-gutter);
                        right: 23.5%;
                        bottom: 0;
                        left: calc((100vw - #{u($structure-width--md)}) / -2);
                        z-index: -1;
                    }

                    @include mq($from: $viewport--sm) {
                        left: u($structure-gutter--l * -1);
                    }

                    @include mq($from: $structure-width--md) {
                        left: calc((100vw - #{u($structure-width--md)}) / -2);
                    }
                }
            }
        }
    }

    &--member {
        padding-top: u($spacing-unit--lg);

        @include mq($from: $viewport--lg) {
            padding-top: u(86px);
        }

        .Grid--member {

            > .Grid-cell {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &--ctaJobs {
        background-color: $cc-grey--light;
        padding-top: u($spacing-unit--lg);
        padding-bottom: u($spacing-unit--lg);

        .Block + & {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        @include mq($from: $viewport--md) {
            padding-top: u(112px);
            padding-bottom: u(120px);

            .Block + & {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .Block-title {
            margin-bottom: u(11px);
        }

        .Lead {
            margin-bottom: u(56px);
        }
    }

    &--blog {
        text-align: center;

        .Block-title {
            margin-bottom: u(8px);
        }
    }

    &--jobs {

        .Block + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--lg) {
                margin-top: u(112px);
            }
        }

        & + .Block--cta {
            margin-top: u(113px);
        }
    }

    &--jobInfo {

        .Block + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(120px);
            }
        }


        .Block-title {
            margin-bottom: u(32px);
        }

        .Lead {
            max-width: u(890px);
        }

        .Button {
            margin-top: u(38px);
        }
    }

    &--apply {
        background-color: $cc-grey--light;
        text-align: center;
        padding-top: u(70px);
        padding-bottom: u(54px);

        .Lead {
            max-width: u(695px);
            margin-right: auto;
            margin-left: auto;
            letter-spacing: 0;
        }

        .Form {
            margin-right: auto;
            margin-left: auto;
            text-align: left;

            &-item--action .Form-controls {
                text-align: center !important;
            }
        }

        .Button {
            margin-top: u(31px);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &--visual {
        @include grid(100%, 1fr);
        background-color: $black;

        .Block + & {
            margin-top: u(113px);
        }
    }

    &--list {

        & + .Block,
        .Block + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(120px);
            }
        }
    }

    &--contact {

        .Block + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(116px);
            }
        }
    }

    &--maps {

        &:last-child {
            margin-bottom: 0;
        }

        .Block + & {
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--md) {
                margin-top: u(112px);
            }
        }
    }
}