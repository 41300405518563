/* Header */
.Header {
    background-color: $white;
    z-index: 3;

    // .has-banner & {
    //     color: $cc-brown;
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    // }

    body:not(.has-banner) & {
        box-shadow: 0 1px 0 0 rgba($black, 0.1);
    }

    > .Container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
