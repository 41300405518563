/* Main */
html {
    background-color: $background-color;
    color: $text-color;
    font-family: $font-source-sans-pro;
    font-weight: 300;
    height: 100%;
    overflow-x: hidden;
}

body {
    min-height: 100%;
    overflow-x: hidden;
}
