/**
 * Components: Cookie
 */

$cookie-background-color:                             $cc-aqua !default;
$cookie-text-color:                                   $white !default;
$cookie-button-background-color:                      $white !default;
$cookie-button-text-color:                            $cc-aqua !default;
$cookie-link-color:                                   $white !default;

// Border
$cookie-border-radius:                                0 !default;
