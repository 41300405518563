/**
 * Paragraph
 */

p {
    margin-bottom: u($spacing-unit);
}

.#{$namespace}Lead {
    font-size: u($font-size);
    letter-spacing: 0.4px;

    @include mq($from: $viewport--md) {
        font-size: u($font-size * $paragraph-lead-modifier);
    }

    + h2,
    + h3 {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u(72px);
        }
    }
}
