/**
 * Settings: Fonts
 */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-oswald:                           "oswald", sans-serif !default;
$font-source-sans-pro:                  "source-sans-pro", sans-serif !default;