/* Logo */
.Logo {
    margin-top: u(-7px);

    &,
    &:hover,
    &:active,
    &:focus {
        color: currentColor;
    }

    svg {
        display: block;
        height: u(50);
        width: u(115);
        *{
            fill: $cc-purple;
        }
    }

    // body.has-banner & {

    //     path {
    //         fill: currentColor !important;
    //     }
    // }
}
